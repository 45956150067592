<template>
  <div class="container" ref="container">
    <div class="ui-border-line ui-mb-3"></div>

    <ul v-if="!init || offerData.length">
      <template v-for="(row, key) in offerData">
        <o-item :row="row" :key="key" :is-simple="true" />
        <div class="ui-border-line ui-mt-3 ui-mb-3" :key="'_' + key"></div>
      </template>
    </ul>
    <blank-list v-else />
  </div>
</template>

<script>
import OItem from '@/components/client/OItem'
import BlankList from '@/components/blank/BlankList'

export default {
  name: 'OfferSearchResult',
  components: {
    OItem,
    BlankList,
  },
  data() {
    return {
      init: false,
      offerData: [],
      offset: {
        offerData: 0,
      },
      que: {
        offerData: false,
      },
      scroll: {
        lastY: 0,
      }
    }
  },
  computed: {
    query: {
      get() {
        return this.$route.query
      },
    }
  },
  created() {
    if (!Object.keys(this.query).length) {
      this.$router.back()
      return
    }

    this.getOfferList()
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.container.addEventListener('scroll', this.getOfferListScroll, false)
    })
  },
  beforeRouteEnter(to, from, next) {
    next(Vue => {
      if (Vue.init) {
        Vue.$refs.container.scrollTop = Vue.scroll.lastY
      } else {
        Vue.getOfferList()
      }
    })
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'home') {
      this.init = false
    }
    setTimeout(() => {
      if (this.$store.state.history.back) {
        next('/home')
      } else {
        this.scroll.lastY = this.$refs.container.scrollTop
        next()
      }
    })
  },
  methods: {
    setData(data) {
      let result = {}

      for (let key in data) {
        if (key === 'location' && (data[key] === '전국' || data[key] == '0')) continue
        if (data[key] === null) continue
        result[key] = data[key]
      }

      return result
    },
    getOfferList() {
      if (this.que.offerData) return
      this.que.offerData = true

      if (!this.init) {
        this.offset.offerData = 0
      }

      const req = {
        method: 'post',
        url: `/client/offer/${this.offset.offerData}/20`,
        data: this.setData(this.query),
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            let offerData = data.response.offerData || []
            this.offerData = this.init ? this.offerData.concat(offerData) : offerData
            this.offset.offerData += offerData.length
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
        .then(() => {
          this.init = true
          setTimeout(() => {
            this.que.offerData = false
          }, 1000)
        })
    },
    getOfferListScroll() {
      if (this.$route.name !== 'OfferSearchResult') return

      let scrollTop = this.$refs.container.scrollTop
      let scrollHeight = this.$refs.container.scrollHeight

      if (scrollHeight <= scrollTop + window.innerHeight) {
        this.getOfferList()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
