var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container", staticClass: "container" },
    [
      _c("div", { staticClass: "ui-border-line ui-mb-3" }),
      !_vm.init || _vm.offerData.length
        ? _c(
            "ul",
            [
              _vm._l(_vm.offerData, function(row, key) {
                return [
                  _c("o-item", {
                    key: key,
                    attrs: { row: row, "is-simple": true }
                  }),
                  _c("div", {
                    key: "_" + key,
                    staticClass: "ui-border-line ui-mt-3 ui-mb-3"
                  })
                ]
              })
            ],
            2
          )
        : _c("blank-list")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }